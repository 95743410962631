<template>
  <div class="auth-default">
    <div class="auth-form-default" v-if="!showLoader">
      <div class="auth-form-default__header">
        <div class="auth-form-default__header-back" @click="goBack">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 12L5 8L9 4" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="auth-form-default__header-title">
          {{ $t('auth.default.title') }}
        </div>
      </div>
      <div class="auth-form-default__body">
        <div class="auth-form-default__body-content">
          {{ $t('auth.default.text') }}
        </div>
        <Button @click="showAuthPopup"  :squared="true" class="auth-form-default__body-btn">
          {{ $t('auth.default.login') }}
        </Button>
      </div>
    </div>
    <Preloader v-else/>
  </div>
</template>
<script>
import Button from "@/components/ui-kit/button/index.vue";
import Preloader from "@/components/ui-kit/preloader-large-flat";
import helper from "@/mixins/helper.js";

export default {
  components: {
    Button,
    Preloader
  },
  mixins: [helper],
  data: () => ({
    showLoader: false
  }),
  methods: {
    goBack() {
      let prevUrl = fakeWindow.sessionStorage.getItem("prevUrl");
      let curUrl =  fakeWindow.sessionStorage.getItem("curUrl");
      if(prevUrl === curUrl) {
        prevUrl = '/'
        fakeWindow.sessionStorage.clear();
        fakeWindow.sessionStorage.setItem('prevUrl', prevUrl);
        fakeWindow.sessionStorage.setItem('curUrl', window.location.pathname);
      }
      fakeWindow.location.href = prevUrl ? prevUrl : '/';
    },
    showAuthPopup(){
      fakeWindow.showVueAuthModal();
    },
    onAuthDone() {
      this.showLoader = true;
      fakeWindow.location.reload();
    }
  },
  mounted() {
    fakeWindow.addEventListener("auth.done", this.onAuthDone);
  },
  destroyed() {
    fakeWindow.removeEventListener("auth.done", this.onAuthDone);
  }
}

</script>
<style lang="scss" scoped>
.auth-default {
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: var.$md) {
    align-items: center;
  }
}

.auth-form-default {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var.$white;
  gap: 24px;

  @media (min-width: var.$md) {
    margin-top: -10%;
    border-radius: 24px;
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    gap: 0;
  }

  &__header {
    padding: 17px 0 20px 0;
    position: relative;

    @media (min-width: var.$md)  {
      padding: 27px 25px;
    }

    &-back {
      width: 28px;
      height: 28px;
      border-radius: 100px;
      background: var.$gray-light;
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 28px;
      top: 24px;
      cursor: pointer;
      transition: all 0.15s ease-out;

      @media (min-width: var.$md) {
        display: flex;
      }

      &:hover {
        background-color: var.$gray-hover;
      }
    }

    &-title {
      color: var.$black;
      text-align: center;
      font-family: var.$font-family-sf-pro-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 122.222% */
      letter-spacing: -0.36px;

      @media (min-width: var.$md) {
        font-size: 18px;
      }
    }
  }
  &__body {
    padding: 0 6px 32px 6px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    align-items: center;

    @media (min-width: var.$md) {
      padding: 0 32px 32px 32px;
    }

    &-content {
      color: var.$text-dark;
      text-align: center;
      font-family: var.$font-family-sf-pro-text;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.26px;
    }

    &-btn {
      width: 100%;
      font-size: 15px;
      font-family: var.$font-family-sf-pro-text;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 160% */
      letter-spacing: -0.24px;
    }
  }
}

</style>
