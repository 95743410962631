import Vue from "vue";
import App from "./app.vue";
import store from "@/store/index";
import { i18n } from "@/plugins/i18n";

export function createApp() {
  const app = new Vue({
    store,
    i18n,
    render: (h) => h(App),
  });
  return { app };
}
