<template>
  <transition name="fade">
    <div class="preloader">
        <div class="preloader__icon">
          <svg-inline class="preloader__icon-svg">
            <icon icon="loader-circle-large" />
          </svg-inline>
        </div>
    </div>
  </transition>
</template>

<script>
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";

export default {
  components: {
    SvgInline,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
// @todo move to global
@keyframes spin {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 44px;
    height: 44px;
    fill: none;
    &-svg {
      color: #fff;
      animation: spin 1s linear infinite;
    }
  }
}
</style>
